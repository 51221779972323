import { useStaticQuery, graphql } from 'gatsby'

export const useRoomQuery = () =>
  useStaticQuery(graphql`
    query {
      room: allSanityRoom {
        nodes {
          _id
          title
          slug {
            current
          }
          detailsCard
          _rawDescription
          bgImg {
            asset {
              _id
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            alt
          }
          bgImg {
            asset {
              _id
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            alt
          }
          gallery {
            images {
              featured
              asset {
                _id
              }
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              alt
            }
          }
        }
      }
    }
  `)
