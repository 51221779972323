import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { useRoomQuery } from '../hooks/rooms'
import { Card } from './Card'

const Button = loadable(() => import('./Button'))

const PageBuilderRooms = ({ block }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  const data = useRoomQuery()
  const { title, showAllRooms, topLine } = block
  const [showMore, setShowMore] = useState(false)

  const rooms =
    (block.specific?.length > 0 && block.specific) || data.room.nodes
  const roomList = Array.from(rooms)
  const cardClasses = 'col-span-8 md:col-span-4'

  if (!block.showAllRooms && !showMore) roomList.splice(2)

  return (
    <section className="container grid grid-cols-12 mx-auto mt-16 mb-8 md:mb-24">
      {topLine && (
        <hr className="col-span-12 mt-4 mb-12 border-gray-400 xl:col-span-10 xl:col-start-2 md:mb-16" />
      )}

      {showAllRooms && (
        <h2 className="col-span-12 mb-8 text-lg font-bold text-gray-800 xl:col-span-10 xl:col-start-2 lg:mb-12">
          {title}
        </h2>
      )}
      <div className="grid grid-cols-8 col-span-12 gap-8 xl:col-span-10 xl:col-start-2 xl:gap-12">
        {roomList.map((room, index) => {
          const featureImage =
            (room.bgImg?.asset && room.bgImg) ||
            room.gallery.images.find(image => image.featured)

          const content = {
            id: room._id,
            title: room.title,
            slug: `/${language === 'en' ? 'rooms' : 'camere'}/${room.slug.current}/`,
            features: room.detailsCard,
            featuredImage: featureImage,
            description: room.description || room._rawDescription,
          }

          return (
            <Card
              key={room._id}
              content={content}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              cardClasses={`${cardClasses}`}
            />
          )
        })}
      </div>
      {rooms.length > 2 && showAllRooms !== true && (
        <div
          className={`col-span-12 xl:col-span-10 xl:col-start-2 pt-12 ${
            showMore ? 'hidden' : ''
          }`}
        >
          <Button
            colour="ghostBlueThick"
            showArrowIcon
            setShowMore={setShowMore}
          >
            {language == 'en' ? 'Explore all rooms' : 'Esplorate tutte le stanze'}
          </Button>
        </div>
      )}
    </section>
  )
}

PageBuilderRooms.defaultProps = {
  block: {},
  // raw: {},
  index: 0,
}

export default PageBuilderRooms
